
import { axiosInstance } from "../../axios";

const GETAllClearance = "api/ClearanceType/GetAll";
const DELETEClearance = "api/ClearanceType/Delete?id=";
const POSTClearance = "api/ClearanceType/Create";


const getAllClearanceType = async function () {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllClearance);
        return parseList(response);
    } catch (error) {
        return null;
    }
};


const deleteClearance = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(DELETEClearance + id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const createClearance = async function (clearance) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTClearance, clearance);
        return response.data;
    } catch (error) {
        debugger;
        console.log(error);
        return null;
    }
};





const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};


export const ClearanceData = {
    getAllClearanceType,
    deleteClearance,
    createClearance
}
