<template>
  <div>
    <ClearanceList></ClearanceList>
  </div>
</template>

<script>
import ClearanceList from './ClearanceList.vue'


export default {
  components: {
    ClearanceList
  },
  methods: {},
};
</script>

