import { render, staticRenderFns } from "./ClearancePage.vue?vue&type=template&id=3156784c&"
import script from "./ClearancePage.vue?vue&type=script&lang=js&"
export * from "./ClearancePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports