<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>Add Clearance Type</h4>
                </div>
            </div>
            <vs-divider class="mb-0" />     
            <div class="vx-col w-full mb-base">
                <vx-card no-shadow>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(saveClearance)">
                            <div class="vx-row mb-6">
                                <div class="vx-col md:w-1/2 w-full">
                                    <ValidationProvider name="ClearanceType" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                                        <vs-input class="w-full" label="Clearance Type" name="ClearanceType" v-model="currentClearance.ClearanceType" />
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                             <vs-divider />
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-button class="mr-3 mb-2" @click.prevent="saveClearance">Submit</vs-button>
                                    <vs-button class="mr-3 mb-2" color="dark" @click="closeWindow">Cancel</vs-button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </vx-card>
            </div>
        </div>
    </vs-sidebar>
</div>
</template>

<script>
import {
    ClearanceData
} from '../../store/api/clearanceType';

import {
    customMessages
} from './../../filters/validationmessage'

export default {
    props: {
        openAddWindow: {
            type: Boolean,
            required: true,
            default: false,
        },
        selectedClearance: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            currentClearance: {},
            active: {},
            customMessages,
            userRole:[
              "Administrator",
              "User"
            ]
        };
    },
    watch: {
        openAddWindow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
        selectedClearance: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.currentClearance = newValue;
            }
        }
    },
    methods: {
        async saveClearance() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await ClearanceData.createClearance(this.currentClearance);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Clearance added",
                color: "success",
                position: "top-center"
            });

            this.$emit("loadClearance");
        },
        closeWindow() {
            this.$emit("loadClearance");
        }
    }
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
